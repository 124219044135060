<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="headline">
                <h1 class="headline__title">Cancel Dispatch</h1>
                <p class="headline__text">Check all dispatch information before cancel a dispatch</p>
            </div>
            <div class="tabs-wrapper">
                <ul class="tabs"> 
                    <li class="tabs__item">
                        <a data-tab="companies" class="nav-tab" @click="switchTab('companies')" :class="isActiveTab('companies')"> Companies </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="products" class="nav-tab" @click="switchTab('products')" :class="isActiveTab('products')"> Products </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="conditions" class="nav-tab" @click="switchTab('conditions')" :class="isActiveTab('conditions')"> Conditions </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="documents" class="nav-tab" @click="switchTab('documents')" :class="isActiveTab('documents')"> Documents </a>
                    </li>
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="сompanies" class="tab-content__item" :class="isActiveTab('companies')">
                        <div class="wizard-item footer-fixed">
                            <div class="company-page__info">
                                <ul>
                                    <li>
                                        <span> Dispatch Number </span>
                                        <p>123456789</p>
                                    </li>
                                    <li>
                                        <span> Owner </span>
                                        <p>Cameron Williamson</p>
                                    </li>
                                    <li>
                                        <span> Delivery Date </span>
                                        <p>02.02.2021, 12:00</p>
                                    </li>
                                </ul>
                            </div>
                            <div class="company-page__info">
                                <ul>
                                    <li>
                                        <span> PO Number </span>
                                        <p>PO-123456789</p>
                                    </li>
                                    <li>
                                        <span> Invoice Number </span>
                                        <p>12345</p>
                                    </li>
                                    <li>
                                        <span> SSCC </span>
                                        <p>(00) 0 0123456 000000001 8</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="products" class="tab-content__item" :class="isActiveTab('products')">
                        <BatchesTab :batches="dispatch.batches" v-if="isActiveTab('products')"></BatchesTab>
                    </div>
                    <div id="conditions" class="tab-content__item" :class="isActiveTab('conditions')">
                        <ConditionsTab :conditions="dispatch.contract_conditions" v-if="isActiveTab('conditions')"></ConditionsTab>
                    </div>
                    <div id="documents" class="tab-content__item" :class="isActiveTab('documents')">
                        <div class="addition footer-fixed">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">Dispatch Documents</h2>
                                    <p class="headline__text">List of documents attached to dispatch</p>
                                </div>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
                                                    <inline-svg :src="require(`@/assets/img/File-Pdf.svg`)" />
                                                </span>
                                                Custom Document Name1.pdf
                                            </li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-transp">View More</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <ConfirmAction
            v-if="cancelDispatch"
            title="Cancel a Dispatch"
            :message="'Do you confirm canceling a dispatch ' + 12312321 + '?'"
            @close="cancelDispatchClose"
            @confirm="cancelDispatchConfirm"
        />
        <SuccessAction v-if="successCancelDispatch" :message="'Dispatch 12312 canceled'" @close="successCancelDispatchClose" />

        <FooterTabsControl
            cancelRoute="/dispatch"
            confirmName="Returt A Dispatch"
            @confirm="confirm"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
        >
        </FooterTabsControl>
    </div>
</template>

<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import Tabs from "@/components/tabs";

import { mixTabs } from '@/mixins';
import { mixDispatches } from '@/mixins/dispatch';

export default {
    name: "DispatchCancel",
    props:['id'],
    components: {
        ...Base,
        ...Popups,
        ...Tabs
    },
    mixins: [mixTabs,mixDispatches],
    data() {
        return {
            cancelDispatch: false,
            successCancelDispatch: false,
            pageTabs:['companies', 'products', 'conditions', 'documents'],
            dispatch:{},
        };
    },
    async created(){
        await this.getDispatch(this.id)
    },
    computed: {
  
    },
    methods: {
      
        openOptions(ev) {            
                let parentElement=ev.target.closest(".spoiler__button")
                parentElement.classList.toggle("active");
                parentElement.nextElementSibling.classList.toggle("active");
        },
        cancelDispatchOpen() {
            this.cancelDispatch = true;
        },
        cancelDispatchClose() {
            this.cancelDispatch = false;
        },
        cancelDispatchConfirm() {
            this.cancelDispatchClose();
            this.successCancelDispatchOpen();
        },
        successCancelDispatchOpen() {
            this.successCancelDispatch = true;
        },
        successCancelDispatchClose() {
            this.successCancelDispatch = false;
        },
        confirm(){
            console.log('confirm')
        }
    },
};
</script>
